import Vue from 'vue';
Vue.config.devtools = true;
Vue.config.productionTip = false;

import Vuetify from 'vuetify';
import Vuelidate from 'vuelidate';
import VueRouter from 'vue-router';
import VueMask from 'v-mask';
import axios from 'axios';
import VueYandexMetrika from 'vue-yandex-metrika';

import Plugins from './plugins';

import debounce from './tools/debounce';
import debounceAsync from './tools/debounce-async';
import dialog from './tools/dialog';
import location from './mixins/location';
import objects from './mixins/objects';
import errorrable from './mixins/errorable';

import '../fude-core/string-format';
import 'vuetify/dist/vuetify.css';
import '@mdi/font/css/materialdesignicons.css'
import './styles/index.less';

Vue.prototype.$http          = axios;
Vue.prototype.$debounce      = debounce;
Vue.prototype.$debounceAsync = debounceAsync;
Vue.prototype.$dialog        = dialog;

Vue.use(Vuetify, {
    iconfont: 'mdi'
});
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VueMask);

Vue.mixin(location);
Vue.mixin(objects);
Vue.mixin(errorrable);

import DialogContainer      from './components/dialog/dialog-container';
import SearchPanel          from './components/search-toolbar';
import Table                from './components/table';
import Form                 from './components/forms/form';
import ImageField           from './components/forms/fields/image-field';
import IconField            from './components/forms/fields/icon-field';
import FileField            from './components/forms/fields/file-field';
import InputField           from './components/forms/fields/input-field';
import ErrorAlert           from './components/error-alert';
import BtnResizable         from './components/buttons/btn-resizable.vue';
import FieldTranslationList from './components/localization/field-translation-list';
import Panel                from './components/panel';

import consts from './consts';

class App {


    constructor() {
        this._mixins = [];
        this._router = new VueRouter({});
        this._settings = [];

        this.prototype('$consts', consts);

        this.component(SearchPanel);
        this.component(DialogContainer);
        this.component(Form);
        this.component(InputField);
        this.component(ImageField);
        this.component(IconField);
        this.component(FileField);
        this.component(Table);
        this.component(ErrorAlert);
        this.component(BtnResizable);
        this.component(FieldTranslationList);
        this.component(Panel);
    }

    setup(settings = {}) {
        this._settings = settings;
        return this;
    }

    settings(options = {}) {
        Vue.use(Plugins.Core,         options);
        Vue.use(Plugins.Metadata,     options);
        Vue.use(Plugins.EventBus,     options);
        Vue.use(Plugins.LocalStorage, options);
        Vue.use(Plugins.RemoteData,   options);
        Vue.use(Plugins.Localization, options);
        Vue.use(Plugins.Dialog,       options);
        Vue.use(VueYandexMetrika, {
            id: 95057981,
            router: this._router,
            env: process.env.NODE_ENV
        });

        if (options.sockets) {
            Vue.use(Plugins.Sockets, options);
        }

        return this;
    }

    get $metadata() {
        return Vue.prototype.$metadata;
    }

    get $consts() {
        return Vue.prototype.$consts;
    }

    use(plugin, options = null) {
        Vue.use(plugin, options);
        return this;
    }

    prototype(name, field) {
        Vue.prototype[name] = field;
        return this;
    }

    consts(values) {
        Object.keys(values).forEach((key) => {
           Vue.prototype.$consts[key] = values[key];
        });
        return this;
    }

    component(component) {
        Vue.component(component.name, component);
        return this;
    }

    components(...components) {
        components.forEach((component) => this.component(component));
        return this;
    }

    directive(directive) {
        Vue.directive(directive.name, directive);
        return this;
    }

    route(path, component, meta) {
        if (typeof path === 'object') {
            let { beforeEach, beforeResolve } = path;
            if (beforeEach) {
                this._router.beforeEach((from, to, next) => beforeEach.call(this._router, from, to, next));
            }
            if (beforeResolve) {
                this._router.beforeResolve((from, to, next) => beforeResolve.call(this._router, from, to, next));
            }
        } else if (component) {
            this.addRoute({
                path,
                component,
                meta
            });
        } else {
            let redirect = (typeof path === 'function')
                ? () => path(this._root)
                : path;
            this.addRoute({
                path     : '*',
                redirect
            });
        }
        return this;
    }

    addRoute(route) {
        if (this._router.addRoute) {
            this._router.addRoute(route);
        } else {
            this._router.addRoutes([route]);
        }
    }

    app(appComponent) {
        this._appComponent = appComponent;
        return this;
    }

    mixin(mixin) {
        if (mixin) {
            this._mixins.push(mixin);
        }
        return this;
    }

    context(component) {
        Vue.prototype.$context = new Vue(component);
        return this;
    }

    async bootstrap() {
        try {
            await Plugins.init();
            if (Vue.prototype.$context) {
                if (Vue.prototype.$context.init) {
                    Vue.prototype.$context.init();
                }
                if (Vue.prototype.$context.$remoteData) {
                    await Vue.prototype.$context.$remoteData.load();
                }
            }
            this._root = new Vue({
                mixins : this._mixins,
                router : this._router,
                render : (h) => h(this._appComponent)
            });
            this._root.$mount('#app');
        }
        catch(error) {
            console.log(error);
        }
    }

    get $core() {
        return Vue.prototype.$core;
    }
}

const app = new App();

export default app;
